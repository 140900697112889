@use "mixin";

.hero-header {
    width: 100%;
    height: 100vh;

    @include mixin.mq(bg-tab) {
        height: auto;
    }

    @include mixin.mq(sp) {
        overflow-x: hidden;
    }

    @include mixin.mq(bg-pc) {
        height: 75vh;    
    }

    .hero-header-container {
        position: relative;

        @include mixin.mq(sp) {
            width: 100%;
            height: 90vh;
        }

        .hero-header-video {
            width: 100%;
            height: auto;

            @include mixin.mq(sp) {
                height: 100%;
                object-fit: cover;
            }
        }

        .scrolldown{
          position: absolute;
          bottom: 18%;
          right: 3.8%;
          animation: arrowmove 1s ease-in-out infinite;

          @include mixin.mq(bg-tab) {
            right: 6%;
          }

          @include mixin.mq(sm-tab) {
            right: 8.2%;
          }

          @include mixin.mq(sp) {
            right: 7.4%;
          }

          @include mixin.mq(bg-pc) {
                right: 2.8%;
          }

          & span {
            font-family: "bovine-mvb", sans-serif;
            font-weight: 400;
            font-style: normal;
            position: absolute;
            right: -30px;
            bottom: 80px;
            color: #fff;
            font-size: 1.4rem;
            letter-spacing: .2em;
            -ms-writing-mode: tb-rl;
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;

                @include mixin.mq(bg-tab) {
                    bottom: 50px;
                }

                @include mixin.mq(sm-tab) {
                    bottom: 24px;
                    right: -22px;
                    font-size: 1em;
                }

                @include mixin.mq(sp) {
                    bottom: 16px;
                    right: -14px;
                    font-size: .6em;
                }
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                right: -6px;
                width: 3px;
                height: 20px;
                background: #fff;
                transform: skewX(-31deg);

                @include mixin.mq(sm-tab) {
                    height: 14px;
                    right: -4px;
                }

                @include mixin.mq(sp) {
                    width: 1px;
                    height: 10px;
                    right: -3px;
                }
            }
        
            &::after{
            content:"";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 3px;
            height: 35em;
            background:#fff;

            @include mixin.mq(bg-tab) {
                height: 20em;
            }

            @include mixin.mq(sm-tab) {
                height: 14em;
            }

            @include mixin.mq(sp) {
                width: 1px;
                height: 22em;
            }

            @include mixin.mq(bg-pc) {
                height: 48em;
            }
            }
        }

        @keyframes arrowmove{
            0%{bottom: 18%;}
            50%{bottom: 21%;}
           100%{bottom: 18%;}
        }

        .hero-header-inner {
            position: absolute;
            bottom: 10%;
            left: 3%;

            @include mixin.mq(bg-tab) {
                bottom: 14%;
            }

            @include mixin.mq(sp) {
                bottom: 22%;   
            }

            .hero-header-inner-head {
                @include mixin.mainFont;
                z-index: 2;
                color: #fff;
                font-size: 12em;
                letter-spacing: -4px;
                line-height: 1.2em;

                @include mixin.mq(bg-tab) {
                    font-size: 8em;
                }

                @include mixin.mq(sm-tab) {
                    font-size: 6em;
                }

                @include mixin.mq(sp) {
                    font-size: 4.2em;
                    letter-spacing: -6px;
                    line-height: 1em;
                }

                @include mixin.mq(bg-pc) {
                    font-size: 18em;
                }

                .hero-head-back-text {
                    position: relative;
                }

                .c::before {
                    content: 'C';
                    position: absolute;
                    top: -88px;
                    left: 10px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: -6px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .r::before {
                    content: 'R';
                    position: absolute;
                    top: -88px;
                    left: 26px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 16px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 34px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .e::before {
                    content: 'E';
                    position: absolute;
                    top: -88px;
                    left: 40px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 26px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 81px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .a::before {
                    content: 'A';
                    position: absolute;
                    top: -88px;
                    left: 58px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                        left: 46px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 32px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 128px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .t::before {
                    content: 'T';
                    position: absolute;
                    top: -88px;
                    left: 72px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                        left: 52px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 38px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 162px; 
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .i::before {
                    content: 'I';
                    position: absolute;
                    top: -88px;
                    left: 92px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                        left: 62px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 44px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 204px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .v::before {
                    content: 'V';
                    position: absolute;
                    top: -88px;
                    left: 96px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                        left: 68px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 46px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 246px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .second-e::before {
                    content: 'E';
                    position: absolute;
                    top: -88px;
                    left: 124px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -50px;
                        left: 82px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -36px;
                        left: 54px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -70px;
                        left: 292px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -132px;
                    }
                }

                .l::before {
                    content: 'L';
                    position: absolute;
                    top: -36px;
                    left: 10px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -15px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -12px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -4px;
                        left: 4px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -54px;
                    }
                }

                .second-a::before {
                    content: 'A';
                    position: absolute;
                    top: -36px;
                    left: 10px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -15px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -12px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -4px;
                        left: 48px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -54px;
                    }
                }

                .b::before {
                    content: 'B';
                    position: absolute;
                    top: -36px;
                    left: 30px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -15px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -12px;
                        left: 18px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -4px;
                        left: 92px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -54px;
                    }
                }

                

                .o::before {
                    content: 'O';
                    position: absolute;
                    top: -36px;
                    left: 50px;
                    font-size: 1.4em;
                    color: transparent;
                    -webkit-text-stroke: 1px #fff;

                    @include mixin.mq(bg-tab) {
                        font-size: 1.2em;
                        top: -15px;
                    }

                    @include mixin.mq(sm-tab) {
                        top: -12px;
                        left: 28px;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.9em;
                        top: -4px;
                        left: 134px;
                    }

                    @include mixin.mq(bg-pc) {
                        top: -54px;
                    }
                }

            }
        }
    }
}

.inner {
    width: 100%;
    padding: 0 6em;
    overflow-x: hidden;

    @include mixin.mq(bg-tab) {
        padding: 0 4em;
    }

    @include mixin.mq(sm-tab) {
        padding: 0 3em;
    }

    @include mixin.mq(sp) {
        padding: 0 1em;
    }

    //スタートコンテンツ
    .start-wrapper {
        width: 100%;
        height: auto;
        
        .start-container {
            margin: 3em auto;

            .start-head {
                @include mixin.mainFont;
                font-size: 7em;
                color: mixin.$subHeadColor;
                letter-spacing: 10px;
                position: relative;

                @include mixin.mq(bg-tab) {
                    font-size: 6em;
                }

                @include mixin.mq(sm-tab) {
                    font-size: 4em;
                }

                @include mixin.mq(sp) {
                    font-size: 2.4em;
                    letter-spacing: 6px;
                }

                @include mixin.mq(bg-pc) {
                    font-size: 12em;
                    letter-spacing: 24px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 3%;
                    width: 120%;
                    height: 2px;
                    background: mixin.$subHeadColor;
                    transform: translateY(-50%);

                    @include mixin.mq(sp) {
                        height: 1px;
                    }
                }
            }

            .start-content {
                @include mixin.mainFont;
                font-size: 2em;
                color: mixin.$subHeadColor;
                letter-spacing: 10px;
                margin: 1em auto;

                @include mixin.mq(bg-tab) {
                    font-size: 1.6em;
                    letter-spacing: 6px;
                }

                @include mixin.mq(sm-tab) {
                    font-size: 1.2em;
                    letter-spacing: 4px;   
                }

                @include mixin.mq(sp) {
                    font-size: .8em;
                    letter-spacing: 2px;
                }

                @include mixin.mq(bg-pc) {
                    font-size: 3em;
                    margin: 1em 0 1em .5em;
                }
            }
        }
    }

    //コンテンツインナーの中身
    .contents-wrapper {
        margin: 18em auto;

        @include mixin.mq(bg-tab) {
            margin: 18em auto 10em;      
        }

        @include mixin.mq(sm-tab) {
            margin: 18em auto 4em;
        }

        @include mixin.mq(sp) {
            margin: 4em auto;
        }

        @include mixin.mq(bg-pc) {
            margin: 18em auto 8em;       
        }

        .contents-container {
            .contents-head {
                @include mixin.mainFont;
                font-size: 7em;
                color: mixin.$subHeadColor;
                letter-spacing: 10px;
                position: relative;

                @include mixin.mq(bg-tab) {
                    font-size: 6em;   
                }

                @include mixin.mq(sm-tab) {
                    font-size: 4em;   
                }

                @include mixin.mq(sp) {
                    font-size: 2.4em;
                    letter-spacing: 6px;
                }

                @include mixin.mq(bg-pc) {
                    font-size: 12em;
                    letter-spacing: 24px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 2%;
                    width: 120%;
                    height: 2px;
                    background: mixin.$subHeadColor;
                    transform: translateY(-50%);
                }
            }

            .design-inner {
                width: 100%;
                height: auto;
                background: rgba(172,181,250,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;   
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .design-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$designColor;

                    @include mixin.mq(sm-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 2em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }
                }

                .design-link {
                    background: mixin.$designColor;

                    &:hover {
                        background: rgba(172,181,250,.6);
                    }
                }
            }

            .prog-inner {
                width: 100%;
                height: auto;
                background: rgba(209,186,229,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;    
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .prog-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$programColor;

                    @include mixin.mq(bg-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sm-tab) {
                        font-size: 1.8em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }


                    &:nth-child(1) {
                        @include mixin.mq(bg-tab) {
                            padding: .2em 0 .2em 7em;
                        }

                        @include mixin.mq(sm-tab) {
                            padding: .2em 0 .2em 7.2em;
                        }

                        @include mixin.mq(sp) {
                            padding: .2em 0 .2em 6.8em;
                        }
                    }
                }

                .prog-link {
                    background: mixin.$programColor;

                    &:hover {
                        background: rgba(209,186,229,.6);
                    }
                }
            }

            .wm-inner {
                width: 100%;
                height: auto;
                background: rgba(239,191,212,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;   
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .wm-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$wmColor;

                    @include mixin.mq(sm-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.2em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }

                    &:nth-child(1) {

                        @include mixin.mq(sp) {
                            padding: .4em 0 .4em 5.6em;
                        }
                    }
                }

                .wm-link {
                    background: mixin.$wmColor;

                    &:hover {
                        background: rgba(239,191,212,.6);
                    }
                }
            }

            .mv-inner {
                width: 100%;
                height: auto;
                background: rgba(244,215,207,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;   
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .mv-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$mvColor;

                    @include mixin.mq(sm-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.2em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }

                    &:nth-child(1) {

                        @include mixin.mq(sp) {
                            padding: .4em 0 .4em 5.6em;
                        }
                    }
                }

                .mv-link {
                    background: mixin.$mvColor;

                    &:hover {
                        background: rgba(244,215,207,.6);
                    }
                }
            }

            .ad-inner {
                width: 100%;
                height: auto;
                background: rgba(255,233,172,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;   
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .ad-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$adColor;

                    @include mixin.mq(sm-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.2em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }

                    &:nth-child(1) {

                        @include mixin.mq(sp) {
                            padding: .4em 0 .4em 5.6em;
                        }
                    }
                }

                .ad-link {
                    background: mixin.$adColor;

                    &:hover {
                        background: rgba(255,233,172,.6);
                    }
                }
            }

            .get-inner {
                width: 100%;
                height: auto;
                background: rgba(190,232,222,.5);
                padding: 5em 0 4em;

                @include mixin.mq(sm-tab) {
                    padding: 3em 0 4em;   
                }

                @include mixin.mq(sp) {
                    padding: 2em 0 1em;
                }

                .get-head {
                    @include mixin.mainFont;
                    font-size: 4em;
                    color: #fff;
                    letter-spacing: 5px;
                    background: mixin.$getColor;

                    @include mixin.mq(sm-tab) {
                        font-size: 2.5em;
                    }

                    @include mixin.mq(sp) {
                        font-size: 1.2em;
                    }

                    @include mixin.mq(bg-pc) {
                        font-size: 6em;
                    }

                    &:nth-child(1) {

                        @include mixin.mq(sp) {
                            padding: .4em 0 .4em 5.6em;
                        }
                    }
                }

                .get-link {
                    background: mixin.$getColor;

                    &:hover {
                        background: rgba(190,232,222,.6);
                    }
                }
            }

            .box {
                border-radius: 60px;
                margin: 5em auto;

                @include mixin.mq(sm-tab) {
                    margin: 3em auto;   
                }

                @include mixin.mq(sp) {
                    margin: 2em auto;
                    border-radius: 30px;
                }
            }

            .box-head {
                margin-left: calc(50% - 50vw);
                padding: .1em 0 .1em 5em;
                position: relative;

                @include mixin.mq(bg-tab) {
                    padding: .1em 0 .1em 4.2em;
                }

                @include mixin.mq(sm-tab) {
                    padding: .1em 0 .1em 5.2em;
                }

                @include mixin.mq(sp) {
                    padding: .2em 0 .1em 3.2em;
                }

                @include mixin.mq(bg-pc) {
                    padding: .2em 0 .1em 3.8em;
                }

                &:before {
                    content: url(../../dest/img/content-head-border.png);
                    position: absolute;
                    top: 50%;
                    left: 12%;
                    transform: translateY(-48%);

                    @include mixin.mq(bg-tab) {
                        left: 15%;
                    }

                    @include mixin.mq(sm-tab) {
                        transform: translateY(-48%) scale(.7);
                        left: 14%;
                    }

                    @include mixin.mq(sp) {
                        transform: translateY(-48%) scale(.5);
                        left: 3%;
                    }

                    @include mixin.mq(bg-pc) {
                        transform: translateY(-58%) scale(1.6);
                        left: 10%;
                    }
                }
            }

            .box-contents {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                row-gap: 5em;
                padding: 4em 3em;

                @include mixin.mq(sm-tab) {
                    grid-template-columns: repeat(2, 1fr);   
                }

                @include mixin.mq(sp) {
                    padding: 2em 1em;
                    row-gap: 1.5em;
                    column-gap: .5em;
                }

                .box-content {
                    margin: 0 1em;
                    text-align: center;

                    @include mixin.mq(sp) {
                        margin: 0 auto;
                    }

                    .box-content-img {
                        width:  100%;
                        height: auto;
                        margin-bottom: 2em;

                        @include mixin.mq(sp) {
                            margin-bottom: .8em;
                        }

                        @include mixin.mq(bg-pc) {
                            margin-bottom: 3em;
                        }
                    }

                    .box-content-link {
                        text-decoration: none;
                        font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
                        color: #fff;
                        font-size: 1.4em;
                        font-weight: lighter;
                        letter-spacing: 6px;
                        border-radius: 40px;
                        transition: all .5s;
                        padding: 1em 4.5em;
                        cursor: pointer;

                        @include mixin.mq(bg-tab) {
                            font-size: 1em;
                            padding: 1em 2em;
                        }

                        @include mixin.mq(sm-tab) {
                            padding: 1em 2.5em;
                        }

                        @include mixin.mq(sp) {
                            font-size: .6em;
                            letter-spacing: 3px;
                            padding: 1.2em 2.8em;
                        }

                        @include mixin.mq(bg-pc) {
                            border-radius: 60px;
                            padding: 1.2em 5.6em;
                            font-size: 1.8em;
                        }
                    }
                }
            }
        }
    }
}

