@charset "UTF-8";
.hero-header {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 1200px) {
  .hero-header {
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  .hero-header {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header {
    height: 75vh;
  }
}
.hero-header .hero-header-container {
  position: relative;
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container {
    width: 100%;
    height: 90vh;
  }
}
.hero-header .hero-header-container .hero-header-video {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-video {
    height: 100%;
    object-fit: cover;
  }
}
.hero-header .hero-header-container .scrolldown {
  position: absolute;
  bottom: 18%;
  right: 3.8%;
  animation: arrowmove 1s ease-in-out infinite;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .scrolldown {
    right: 6%;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .scrolldown {
    right: 8.2%;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .scrolldown {
    right: 7.4%;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .scrolldown {
    right: 2.8%;
  }
}
.hero-header .hero-header-container .scrolldown span {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  right: -30px;
  bottom: 80px;
  color: #fff;
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .scrolldown span {
    bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .scrolldown span {
    bottom: 24px;
    right: -22px;
    font-size: 1em;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .scrolldown span {
    bottom: 16px;
    right: -14px;
    font-size: 0.6em;
  }
}
.hero-header .hero-header-container .scrolldown::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: -6px;
  width: 3px;
  height: 20px;
  background: #fff;
  transform: skewX(-31deg);
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .scrolldown::before {
    height: 14px;
    right: -4px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .scrolldown::before {
    width: 1px;
    height: 10px;
    right: -3px;
  }
}
.hero-header .hero-header-container .scrolldown::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 35em;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .scrolldown::after {
    height: 20em;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .scrolldown::after {
    height: 14em;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .scrolldown::after {
    width: 1px;
    height: 22em;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .scrolldown::after {
    height: 48em;
  }
}
@keyframes arrowmove {
  0% {
    bottom: 18%;
  }
  50% {
    bottom: 21%;
  }
  100% {
    bottom: 18%;
  }
}
.hero-header .hero-header-container .hero-header-inner {
  position: absolute;
  bottom: 10%;
  left: 3%;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner {
    bottom: 14%;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner {
    bottom: 22%;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  z-index: 2;
  color: #fff;
  font-size: 12em;
  letter-spacing: -4px;
  line-height: 1.2em;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head {
    font-size: 8em;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head {
    font-size: 4.2em;
    letter-spacing: -6px;
    line-height: 1em;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head {
    font-size: 18em;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .hero-head-back-text {
  position: relative;
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .c::before {
  content: "C";
  position: absolute;
  top: -88px;
  left: 10px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .c::before {
    font-size: 1.2em;
    top: -50px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .c::before {
    top: -36px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .c::before {
    font-size: 1.9em;
    top: -70px;
    left: -6px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .c::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .r::before {
  content: "R";
  position: absolute;
  top: -88px;
  left: 26px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .r::before {
    font-size: 1.2em;
    top: -50px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .r::before {
    top: -36px;
    left: 16px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .r::before {
    font-size: 1.9em;
    top: -70px;
    left: 34px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .r::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .e::before {
  content: "E";
  position: absolute;
  top: -88px;
  left: 40px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .e::before {
    font-size: 1.2em;
    top: -50px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .e::before {
    top: -36px;
    left: 26px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .e::before {
    font-size: 1.9em;
    top: -70px;
    left: 81px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .e::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .a::before {
  content: "A";
  position: absolute;
  top: -88px;
  left: 58px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .a::before {
    font-size: 1.2em;
    top: -50px;
    left: 46px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .a::before {
    top: -36px;
    left: 32px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .a::before {
    font-size: 1.9em;
    top: -70px;
    left: 128px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .a::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .t::before {
  content: "T";
  position: absolute;
  top: -88px;
  left: 72px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .t::before {
    font-size: 1.2em;
    top: -50px;
    left: 52px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .t::before {
    top: -36px;
    left: 38px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .t::before {
    font-size: 1.9em;
    top: -70px;
    left: 162px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .t::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .i::before {
  content: "I";
  position: absolute;
  top: -88px;
  left: 92px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .i::before {
    font-size: 1.2em;
    top: -50px;
    left: 62px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .i::before {
    top: -36px;
    left: 44px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .i::before {
    font-size: 1.9em;
    top: -70px;
    left: 204px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .i::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .v::before {
  content: "V";
  position: absolute;
  top: -88px;
  left: 96px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .v::before {
    font-size: 1.2em;
    top: -50px;
    left: 68px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .v::before {
    top: -36px;
    left: 46px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .v::before {
    font-size: 1.9em;
    top: -70px;
    left: 246px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .v::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-e::before {
  content: "E";
  position: absolute;
  top: -88px;
  left: 124px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-e::before {
    font-size: 1.2em;
    top: -50px;
    left: 82px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-e::before {
    top: -36px;
    left: 54px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-e::before {
    font-size: 1.9em;
    top: -70px;
    left: 292px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-e::before {
    top: -132px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .l::before {
  content: "L";
  position: absolute;
  top: -36px;
  left: 10px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .l::before {
    font-size: 1.2em;
    top: -15px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .l::before {
    top: -12px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .l::before {
    font-size: 1.9em;
    top: -4px;
    left: 4px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .l::before {
    top: -54px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-a::before {
  content: "A";
  position: absolute;
  top: -36px;
  left: 10px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-a::before {
    font-size: 1.2em;
    top: -15px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-a::before {
    top: -12px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-a::before {
    font-size: 1.9em;
    top: -4px;
    left: 48px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .second-a::before {
    top: -54px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .b::before {
  content: "B";
  position: absolute;
  top: -36px;
  left: 30px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .b::before {
    font-size: 1.2em;
    top: -15px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .b::before {
    top: -12px;
    left: 18px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .b::before {
    font-size: 1.9em;
    top: -4px;
    left: 92px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .b::before {
    top: -54px;
  }
}
.hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .o::before {
  content: "O";
  position: absolute;
  top: -36px;
  left: 50px;
  font-size: 1.4em;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
@media screen and (max-width: 1200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .o::before {
    font-size: 1.2em;
    top: -15px;
  }
}
@media screen and (max-width: 768px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .o::before {
    top: -12px;
    left: 28px;
  }
}
@media screen and (max-width: 420px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .o::before {
    font-size: 1.9em;
    top: -4px;
    left: 134px;
  }
}
@media screen and (min-width: 2200px) {
  .hero-header .hero-header-container .hero-header-inner .hero-header-inner-head .o::before {
    top: -54px;
  }
}

.inner {
  width: 100%;
  padding: 0 6em;
  overflow-x: hidden;
}
@media screen and (max-width: 1200px) {
  .inner {
    padding: 0 4em;
  }
}
@media screen and (max-width: 768px) {
  .inner {
    padding: 0 3em;
  }
}
@media screen and (max-width: 420px) {
  .inner {
    padding: 0 1em;
  }
}
.inner .start-wrapper {
  width: 100%;
  height: auto;
}
.inner .start-wrapper .start-container {
  margin: 3em auto;
}
.inner .start-wrapper .start-container .start-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 7em;
  color: #acb5fa;
  letter-spacing: 10px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .inner .start-wrapper .start-container .start-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 768px) {
  .inner .start-wrapper .start-container .start-head {
    font-size: 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .start-wrapper .start-container .start-head {
    font-size: 2.4em;
    letter-spacing: 6px;
  }
}
@media screen and (min-width: 2200px) {
  .inner .start-wrapper .start-container .start-head {
    font-size: 12em;
    letter-spacing: 24px;
  }
}
.inner .start-wrapper .start-container .start-head:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 3%;
  width: 120%;
  height: 2px;
  background: #acb5fa;
  transform: translateY(-50%);
}
@media screen and (max-width: 420px) {
  .inner .start-wrapper .start-container .start-head:after {
    height: 1px;
  }
}
.inner .start-wrapper .start-container .start-content {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 2em;
  color: #acb5fa;
  letter-spacing: 10px;
  margin: 1em auto;
}
@media screen and (max-width: 1200px) {
  .inner .start-wrapper .start-container .start-content {
    font-size: 1.6em;
    letter-spacing: 6px;
  }
}
@media screen and (max-width: 768px) {
  .inner .start-wrapper .start-container .start-content {
    font-size: 1.2em;
    letter-spacing: 4px;
  }
}
@media screen and (max-width: 420px) {
  .inner .start-wrapper .start-container .start-content {
    font-size: 0.8em;
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 2200px) {
  .inner .start-wrapper .start-container .start-content {
    font-size: 3em;
    margin: 1em 0 1em 0.5em;
  }
}
.inner .contents-wrapper {
  margin: 18em auto;
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper {
    margin: 18em auto 10em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper {
    margin: 18em auto 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper {
    margin: 4em auto;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper {
    margin: 18em auto 8em;
  }
}
.inner .contents-wrapper .contents-container .contents-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 7em;
  color: #acb5fa;
  letter-spacing: 10px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .contents-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .contents-head {
    font-size: 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .contents-head {
    font-size: 2.4em;
    letter-spacing: 6px;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .contents-head {
    font-size: 12em;
    letter-spacing: 24px;
  }
}
.inner .contents-wrapper .contents-container .contents-head:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2%;
  width: 120%;
  height: 2px;
  background: #acb5fa;
  transform: translateY(-50%);
}
.inner .contents-wrapper .contents-container .design-inner {
  width: 100%;
  height: auto;
  background: rgba(172, 181, 250, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .design-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .design-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .design-inner .design-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #acb5fa;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .design-inner .design-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .design-inner .design-head {
    font-size: 2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .design-inner .design-head {
    font-size: 6em;
  }
}
.inner .contents-wrapper .contents-container .design-inner .design-link {
  background: #acb5fa;
}
.inner .contents-wrapper .contents-container .design-inner .design-link:hover {
  background: rgba(172, 181, 250, 0.6);
}
.inner .contents-wrapper .contents-container .prog-inner {
  width: 100%;
  height: auto;
  background: rgba(209, 186, 229, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .prog-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .prog-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .prog-inner .prog-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #d1bae5;
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head {
    font-size: 1em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head:nth-child(1) {
    padding: 0.2em 0 0.2em 7em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head:nth-child(1) {
    padding: 0.2em 0 0.2em 7.2em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .prog-inner .prog-head:nth-child(1) {
    padding: 0.2em 0 0.2em 6.8em;
  }
}
.inner .contents-wrapper .contents-container .prog-inner .prog-link {
  background: #d1bae5;
}
.inner .contents-wrapper .contents-container .prog-inner .prog-link:hover {
  background: rgba(209, 186, 229, 0.6);
}
.inner .contents-wrapper .contents-container .wm-inner {
  width: 100%;
  height: auto;
  background: rgba(239, 191, 212, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .wm-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .wm-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .wm-inner .wm-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #efbfd4;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .wm-inner .wm-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .wm-inner .wm-head {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .wm-inner .wm-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .wm-inner .wm-head:nth-child(1) {
    padding: 0.4em 0 0.4em 5.6em;
  }
}
.inner .contents-wrapper .contents-container .wm-inner .wm-link {
  background: #efbfd4;
}
.inner .contents-wrapper .contents-container .wm-inner .wm-link:hover {
  background: rgba(239, 191, 212, 0.6);
}
.inner .contents-wrapper .contents-container .mv-inner {
  width: 100%;
  height: auto;
  background: rgba(244, 215, 207, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .mv-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .mv-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .mv-inner .mv-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #f4d7cf;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .mv-inner .mv-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .mv-inner .mv-head {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .mv-inner .mv-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .mv-inner .mv-head:nth-child(1) {
    padding: 0.4em 0 0.4em 5.6em;
  }
}
.inner .contents-wrapper .contents-container .mv-inner .mv-link {
  background: #f4d7cf;
}
.inner .contents-wrapper .contents-container .mv-inner .mv-link:hover {
  background: rgba(244, 215, 207, 0.6);
}
.inner .contents-wrapper .contents-container .ad-inner {
  width: 100%;
  height: auto;
  background: rgba(255, 233, 172, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .ad-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .ad-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .ad-inner .ad-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #ffe9ac;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .ad-inner .ad-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .ad-inner .ad-head {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .ad-inner .ad-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .ad-inner .ad-head:nth-child(1) {
    padding: 0.4em 0 0.4em 5.6em;
  }
}
.inner .contents-wrapper .contents-container .ad-inner .ad-link {
  background: #ffe9ac;
}
.inner .contents-wrapper .contents-container .ad-inner .ad-link:hover {
  background: rgba(255, 233, 172, 0.6);
}
.inner .contents-wrapper .contents-container .get-inner {
  width: 100%;
  height: auto;
  background: rgba(190, 232, 222, 0.5);
  padding: 5em 0 4em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .get-inner {
    padding: 3em 0 4em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .get-inner {
    padding: 2em 0 1em;
  }
}
.inner .contents-wrapper .contents-container .get-inner .get-head {
  font-family: "bovine-mvb", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 4em;
  color: #fff;
  letter-spacing: 5px;
  background: #bee8de;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .get-inner .get-head {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .get-inner .get-head {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .get-inner .get-head {
    font-size: 6em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .get-inner .get-head:nth-child(1) {
    padding: 0.4em 0 0.4em 5.6em;
  }
}
.inner .contents-wrapper .contents-container .get-inner .get-link {
  background: #bee8de;
}
.inner .contents-wrapper .contents-container .get-inner .get-link:hover {
  background: rgba(190, 232, 222, 0.6);
}
.inner .contents-wrapper .contents-container .box {
  border-radius: 60px;
  margin: 5em auto;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .box {
    margin: 3em auto;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box {
    margin: 2em auto;
    border-radius: 30px;
  }
}
.inner .contents-wrapper .contents-container .box-head {
  margin-left: calc(50% - 50vw);
  padding: 0.1em 0 0.1em 5em;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .box-head {
    padding: 0.1em 0 0.1em 4.2em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .box-head {
    padding: 0.1em 0 0.1em 5.2em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-head {
    padding: 0.2em 0 0.1em 3.2em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .box-head {
    padding: 0.2em 0 0.1em 3.8em;
  }
}
.inner .contents-wrapper .contents-container .box-head:before {
  content: url(../../dest/img/content-head-border.png);
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translateY(-48%);
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .box-head:before {
    left: 15%;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .box-head:before {
    transform: translateY(-48%) scale(0.7);
    left: 14%;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-head:before {
    transform: translateY(-48%) scale(0.5);
    left: 3%;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .box-head:before {
    transform: translateY(-58%) scale(1.6);
    left: 10%;
  }
}
.inner .contents-wrapper .contents-container .box-contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 5em;
  padding: 4em 3em;
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .box-contents {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-contents {
    padding: 2em 1em;
    row-gap: 1.5em;
    column-gap: 0.5em;
  }
}
.inner .contents-wrapper .contents-container .box-contents .box-content {
  margin: 0 1em;
  text-align: center;
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content {
    margin: 0 auto;
  }
}
.inner .contents-wrapper .contents-container .box-contents .box-content .box-content-img {
  width: 100%;
  height: auto;
  margin-bottom: 2em;
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-img {
    margin-bottom: 0.8em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-img {
    margin-bottom: 3em;
  }
}
.inner .contents-wrapper .contents-container .box-contents .box-content .box-content-link {
  text-decoration: none;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  color: #fff;
  font-size: 1.4em;
  font-weight: lighter;
  letter-spacing: 6px;
  border-radius: 40px;
  transition: all 0.5s;
  padding: 1em 4.5em;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-link {
    font-size: 1em;
    padding: 1em 2em;
  }
}
@media screen and (max-width: 768px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-link {
    padding: 1em 2.5em;
  }
}
@media screen and (max-width: 420px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-link {
    font-size: 0.6em;
    letter-spacing: 3px;
    padding: 1.2em 2.8em;
  }
}
@media screen and (min-width: 2200px) {
  .inner .contents-wrapper .contents-container .box-contents .box-content .box-content-link {
    border-radius: 60px;
    padding: 1.2em 5.6em;
    font-size: 1.8em;
  }
}